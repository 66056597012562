body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-theme-quartz {
  font-size: 0.8rem;
  --ag-odd-row-background-color: #F8FAFB;
  --ag-header-cell-hover-background-color: #42475F !important;
  --ag-header-cell-moving-background-color: #42475F !important;
  
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #FFFFFF;

  --ag-header-column-resize-handle-width: 0px;

  --ag-icon-font-color-filter: #FFFFFF;
}

.ag-theme-quartz .ag-header-cell {
  background-color: #42475F;
  color: #FFFFFF;
}

.ag-root-wrapper.ag-layout-normal{
  width: 100%;
  border-radius: 0;
}
